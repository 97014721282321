import {format, distanceInWords, differenceInDays} from 'date-fns'
import nb from 'date-fns/locale/nb'
import React from 'react'
import PortableText from './portableText'
import Container from './container'
import EmployeeList from './employee-list'

import styles from './blog-post.module.css'

function BlogPost (props) {
  const {_rawBody, authors, title, publishedAt} = props
  return (
    <article className={styles.root}>
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
          <aside className={styles.metaContent}>
            {publishedAt && (
              <div className={styles.publishedAt}>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(props.publishedAt, 'Do MMMM YYYY', {locale: nb})}
              </div>
            )}
            {authors && <EmployeeList items={authors} title='Authors' />}
          </aside>
        </div>
      </Container>
    </article>
  )
}

export default BlogPost
