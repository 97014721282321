import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import styles from "./employee-list.module.css";

function EmployeePreview({ items, title }) {
  if (items && items.length > 0) {
    return (
      <div className={styles.root}>
        <h2 className={styles.headline}>{title}</h2>
        <ul className={styles.list}>
          {items.map(({ Employee, _key }) => {
            const EmployeeName = Employee && Employee.name;
            return (
              <li key={_key} className={styles.listItem}>
                <div>
                  <div className={styles.avatar}>
                    {Employee && Employee.image && Employee.image.asset && (
                      <img
                        src={imageUrlFor(buildImageObj(Employee.image))
                          .width(100)
                          .height(100)
                          .fit("crop")
                          .url()}
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div>
                  <div>{EmployeeName || <em>Mangler navn</em>}</div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default EmployeePreview;
